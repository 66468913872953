import * as React from "react"
// @ts-ignore
import { A, Button, H2, Navbar, Container, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from "@bootstrap-styled/v4"
// @ts-ignore
import BootstrapProvider from '@bootstrap-styled/provider';

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "TypeScript Documentation",
  url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative" as "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    badge: true,
    text: "Assassin",
    url: "/class/assassin",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "Dervish",
    url: "/class/dervish",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Elementalist",
    url: "/class/elementalist",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Mesmer",
    url: "/class/mesmer",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Monk",
    url: "/class/monk",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Necromancer",
    url: "/class/necromancer",
    description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Paragon",
    url: "/class/paragon",
    description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Ranger",
    url: "/class/ranger",
    description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Ritualist",
    url: "/class/ritualist",
    description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Warrior",
    url: "/class/warrior",
    description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  }
]

// markup
const IndexPage = () => {
  const [isOpen, setState] = React.useState(false);

  return (
    <BootstrapProvider style={pageStyles}>
    <Navbar color="faded" light toggleable="lg">
      <Container>
        <div className="d-flex justify-content-between">
          <NavbarBrand tag={A} to="javascript:;">Class Selection</NavbarBrand>
          <NavbarToggler onClick={() => setState( !isOpen )} />
        </div>
        <Collapse navbar isOpen={isOpen}>
          <Nav navbar className="mr-auto">
            <NavItem>
              <NavLink href="/" active>Home</NavLink>
            </NavItem>
            {links.map(link => (
                <NavItem key={link.url} >
                  <NavLink href={link.url}>{link.text}</NavLink>
                </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>

    <main>
      <title>Home - Guild Wars Guide</title>
      <Container>
      <H2 style={headingStyles}>
        Guild Wars Guide
      </H2>
      <p style={paragraphStyles}>
        Please select a class.
      </p>
      </Container>
    </main>
    </BootstrapProvider>
  )
}

export default IndexPage
